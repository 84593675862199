<template>
  <div class="fd-list-item cursor-pointer" @click="$emit('click')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fd-list-item {
  padding: 15px 25px;
  color: #777;
  font-weight: normal;

  &:hover {
    background-color: darken(white, 5);
    color: #333;
  }
}
</style>
