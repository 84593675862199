var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fd-dropdown"},[_c('button',{staticClass:"btn",class:_vm.buttonClass,style:(_vm.buttonStyle),attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleDropdown($event)}}},[(!_vm.$slots.buttonContent)?_c('span',[_vm._v(_vm._s(_vm.label))]):_c('div',[_vm._t("buttonContent")],2)]),_c('Portal',{attrs:{"to":"dropdown-list"}},[(_vm.isDropdownShown)?_c('div',{staticClass:"dropdown-wrapper",on:{"click":function($event){return _vm.closeDropdown()}}},[_c('div',{staticClass:"dropdown-container",style:({
          left: _vm.position.left,
          top: _vm.position.top,
          right: _vm.position.right,
          bottom: _vm.position.bottom
        }),attrs:{"id":"dropdown-container"}},[(!_vm.$slots.default)?_c('div',{staticClass:"dropdown-content",class:_vm.dropdownClass},[_c('div',{staticClass:"card text-left"},_vm._l((_vm.dropdownOptions),function(item,index){return _c('fd-list-item',{key:index,staticClass:"dropdown-item",on:{"click":function($event){item.onClick
                  ? item.onClick
                  : function () {
                      return null;
                    }}}},[_vm._v(_vm._s(item.label))])}),1)]):_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.contentClick($event)}}},[_vm._t("default")],2)])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }