<template>
  <div class="fd-dropdown">
    <!-- The button -->
    <button
      type="button"
      class="btn"
      :class="buttonClass"
      :style="buttonStyle"
      @click.stop="toggleDropdown"
    >
      <span v-if="!$slots.buttonContent">{{ label }}</span>

      <div v-else>
        <slot name="buttonContent"></slot>
      </div>
    </button>

    <Portal to="dropdown-list">
      <!-- The dropdown -->
      <div
        v-if="isDropdownShown"
        class="dropdown-wrapper"
        @click="closeDropdown()"
      >
        <div
          id="dropdown-container"
          class="dropdown-container"
          :style="{
            left: position.left,
            top: position.top,
            right: position.right,
            bottom: position.bottom
          }"
        >
          <div
            v-if="!$slots.default"
            class="dropdown-content"
            :class="dropdownClass"
          >
            <div class="card text-left">
              <fd-list-item
                v-for="(item, index) in dropdownOptions"
                :key="index"
                class="dropdown-item"
                @click="
                  item.onClick
                    ? item.onClick
                    : () => {
                        return null;
                      }
                "
                >{{ item.label }}</fd-list-item
              >
            </div>
          </div>
          <div v-else @click.stop="contentClick">
            <slot></slot>
          </div>
        </div>
      </div>
    </Portal>
  </div>
</template>

<script>
/**
 * :label[String] The label for the button
 * :flippable[Boolean] Make dropdown content flip when it's over the window boundary
 * :buttonClass[String] The css class for button
 * :dropdownClass[String] The css class for dropdown
 * :dropdownItems[Array] The array of items for dropdown
 *    Example:
 *    [
 *      {
 *        label: "Dropdown Label",
 *        onClick: yourFunction();
 *      }
 *    ]
 *
 * Slots:
 * #buttonContent Replace the content in button
 * #dropdownContent Replace the content in dropdown
 *
 */
import FdListItem from "@/components/GlobalComponents/List/FdListItem";
export default {
  components: { FdListItem },
  mixins: [],
  props: {
    label: {
      type: String,
      default: ""
    },
    flippable: {
      type: Boolean,
      default: true
    },
    closeOnContentClick: {
      type: Boolean,
      default: true
    },
    buttonClass: {
      type: String,
      default: ""
    },
    buttonStyle: {
      type: [String, Object, Array]
    },
    dropdownClass: {
      type: String,
      default: ""
    },
    dropdownOptions: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      isDropdownShown: false,
      position: {
        left: "0px",
        top: "0px",
        right: "0px",
        bottom: "0px"
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  beforeDestroy() {},
  methods: {
    closeDropdown() {
      this.isDropdownShown = false;
    },
    toggleDropdown(e) {
      this.isDropdownShown = !this.isDropdownShown;
      if (this.isDropdownShown) {
        this.$emit("openDropdown");
      }

      this.$nextTick(() => {
        this.setMenuPosition(e.currentTarget);
      });
    },
    contentClick() {
      if (this.closeOnContentClick) {
        this.closeDropdown();
      }
    },
    setMenuPosition: function (buttonElem) {
      let buttonRect = buttonElem.getBoundingClientRect();
      this.position.left = `${buttonRect.left}px`;
      this.position.top = `${buttonRect.bottom}px`;

      this.$nextTick(() => {
        let container = document
          .querySelector(".dropdown-container")
          .getBoundingClientRect();

        // if dropdown right over screen width, reposition horizontal flipped
        if (buttonRect.right + container.width > window.innerWidth) {
          this.position.right = `${window.innerWidth - buttonRect.right}px`;
          this.position.left = `auto`;
        }
        // if dropdown bottom over screen height, reposition vertical flipped
        if (buttonRect.bottom + container.height > window.innerHeight) {
          this.position.bottom = `${window.innerHeight - buttonRect.top}px`;
          this.position.top = `auto`;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.fd-dropdown {
  position: relative;
  display: inline-block;

  .dropdown-wrapper {
    z-index: 99999;
    position: fixed;
    background: transparent;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: default;

    .dropdown-container {
      z-index: 3;
      background-color: white;
      position: absolute;
      width: max-content;
      height: fit-content;
    }
  }
}
</style>
